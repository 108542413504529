@use "../foundation/variables" as *;
@use "../foundation/responsive" as *;

.p-front-page{
  min-width: $laptop;
}

.p-page{
  position: relative;
  &::before{
    position: absolute;
    top: 0;
    z-index: 1;
    display: block;
    width: 100%;
    min-width: $laptop;
    height: 100px;
    content: '';
    background: linear-gradient(to bottom, #e5e5e5, #fff);
  }
  & > .container{
    position: relative;
    z-index: 2;
  }
}