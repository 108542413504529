@use "../foundation/variables" as *;
@use "../foundation/responsive" as *;

.c-content{
  min-height: 300px;
  line-height: 1.5;
  p,ul,ol,h1,h2,h3,h4,h5,h6{
    margin-bottom: $space-xs;
  }
  ul{
    margin-left: 16px;
    list-style: disc;
  }
  ol {
    margin-left: 16px;
    list-style: decimal;
  }
  strong{
    font-weight: bold;
  }
  em{
    font-style: italic;
  }
  h1{font-size: 36px;}
  h2{font-size: 32px;}
  h3{font-size: 28px;}
  h4{font-size: 24px;}
  h5{font-size: 20px;}
  h6{font-size: 16px;}
  h1,h2,h3,h4,h5,h6{
    font-weight: bold;
  }
}