@use "../foundation/variables" as *;
@use "../foundation/responsive" as *;

.tabs {
  width: 100%;
}

/*タブのスタイル*/
.tab_item {
  display: flex;
  align-items: center;
  float: left;
  height: 50px;
  padding: $space-xs;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  background: linear-gradient(to bottom, #fff 70%, #e7e7e7 100%);
  border-top: 2px solid #e9e9e9;
  border-bottom: 2px solid #e9e9e9;
  border-left: 2px solid #e9e9e9;
  
  &.last {
    border-right: 2px solid #e9e9e9;
  }
  &:hover {
    opacity: 0.75;
  }
}

/*ラジオボタンを全て消す*/
input[name="tab_item"] {
  display: none;
}

/*タブ切り替えの中身のスタイル*/
.tab_content {
  display: none;
  padding: $space-m 0;
  overflow: hidden;
  clear: both;
  border-top: 2px solid #e9e9e9;
  @media only screen and(max-width: $tablet){
    padding: 16px;
  }
}


/*選択されているタブのコンテンツのみを表示*/
#background:checked ~ #background_content,
#mission:checked ~ #mission_content,
#chairman:checked ~ #chairman_content {
  display: block;
}

/*選択されているタブのスタイルを変える*/
.tabs input:checked + .tab_item {
  background: #fff;
  border-bottom: 2px solid #fff;
}
.main-content{
  position: relative;
  overflow: hidden;
  &:after {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1px;
    height: 100%;
    content: '';
    background-color: #ccc;
    transform: translateX(-50%)
  }
}
