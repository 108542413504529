@use "../foundation/variables" as *;
@use "../foundation/responsive" as *;

.card{
  border: 2px solid #e9e9e9;
  &-header{
    display: flex;
    align-items: center;
    height: 50px;
    padding: $space-xs;
    font-weight: bold;
    text-align: left;
    background: linear-gradient(to bottom, #fff 70%, #e7e7e7 100%);
    // border-top: 2px solid #e9e9e9;
    border-bottom: 2px solid #e9e9e9;
    // border-left: 2px solid #e9e9e9;
  }
  &-body{
    padding: $space-xs;
    & > ol{
      counter-reset: item;
      & > li{
        position: relative;
        padding-left: $space-s;
        &:not(:last-child){
          margin-bottom: $space-xs;
        }
        &::before{
          position: absolute;
          left: 0;
          content: counter(item)'.';
          counter-increment: item;
        }
      }
    }
  }
}