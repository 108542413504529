@use "../foundation/variables" as *;
@use "../foundation/responsive" as *;

.noscroll{
  overflow: hidden;
}


.c-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: $laptop;
  background: #fff;
  border-bottom: 3px solid #e71a35;
  &>.container {
    display: flex;
    flex-basis: 1;
    justify-content: space-between;
  }

  &__logo {
    width: 160px;
    padding: $space-xxs 0 $space-xxs $space-s;
  }
  &__nav {
    display: flex;
    flex-basis: 1;
    align-items: center;
    align-self: flex-end;
    max-width: calc(100% - 400px);

    &.-open{
      opacity: 1;
      transition: opacity 0.3s;
    }
    &.-visible{
      display: block;
    }
    &.active{
      opacity: 1;
      transition: opacity 0.3s;
    }
    & > ul{
      display: flex;
      align-items: center;

    }
  }
}

@mixin c-header {
  .c-header & {
    @content;
  }
}

.menu-item {
  @include c-header() {
    padding: $space-xs;
    background: linear-gradient(to bottom, #fff 70%, #e7e7e7 100%);
    border-top: 2px solid #e9e9e9;
    border-bottom: 2px solid #e9e9e9;
    border-left: 2px solid #e9e9e9;
    &:last-child{
      border-right: 2px solid #e9e9e9;
    }
    & a{
      color: #000;
      text-decoration: none;
    }
  }
}
