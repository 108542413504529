@use 'node_modules/bootstrap/scss/bootstrap-grid.scss';
@use 'node_modules/bootstrap/scss/bootstrap.scss';
@use 'node_modules/bootstrap/scss/bootstrap-utilities.scss';
@use "stylesheets/foundation/variables" as *;
@use "stylesheets/foundation/responsive" as *;
@use "stylesheets/foundation/_mixins.scss";
@use "stylesheets/foundation/_override.scss";
@use "stylesheets/foundation/_reset.scss";
@use "stylesheets/foundation/_responsive.scss";
@use "stylesheets/foundation/_variables.scss";;
;
@use "stylesheets/foundation/variables" as *;
@use "stylesheets/components/_card.scss";
@use "stylesheets/components/_content.scss";
@use "stylesheets/components/_footer.scss";
@use "stylesheets/components/_header.scss";
@use "stylesheets/components/_menu.scss";
@use "stylesheets/components/_page.scss";
@use "stylesheets/components/_pagination.scss";
@use "stylesheets/components/_tab.scss";;


* {
  box-sizing: border-box;
  margin: 0;
}

body{
  font-family: Arial, Helvetica, sans-serif;
}

img {
  width: 100%;
  height: auto;
}

p{
  font-size: $font-xxs;
  color: $color-default;
}
.disable-auto-tel a[href^="tel:"] {
  color: inherit;
  text-decoration: none;
  pointer-events: none;
}

.container{
  width: $laptop !important;
  max-width: $laptop;
  margin: 0 auto;
}