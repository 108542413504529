@use "../foundation/variables" as *;
@use "../foundation/responsive" as *;

.menu{
  & > .cat-item > a{
    border-bottom: dotted 1px #d8d8d8;
  }
  & .current-cat{
    & > a {
      background-color: #F4F4F4;
      border-right: 4px solid $bg-primary;
    }
  }
  & .cat-item{
    text-align: right;
    background-color: #fff;
    & > a{
      display: block;
      padding: $space-xs $space-xs;
      color: #000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .children{
    & > .cat-item {
      border-bottom: dotted 1px #d8d8d8;
    }
  }
}
