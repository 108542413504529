@use "../foundation/variables" as *;
@use "../foundation/responsive" as *;

.pagination{
  position: relative;
  padding: $space-s 0;
  .pager{
    padding: $space-xxs 0.6rem;
    margin-right: $space-xxs;
    color: #fff;
    text-decoration: none;
    background-color: $bg-secondary;
  }
  .current{
    background-color: $bg-primary;
  }
  .link{
    transition: 0.3s;
    &:hover{
      background-color: $bg-primary;
      transition: 0.3s;
    }
  }
  .next, .prev{
    padding: $space-xxs 0;
    color: #000;
    text-decoration: none;
  }
  .next{
    margin-left: $space-xs;
  }
  .prev{
    margin-right: $space-xs;
  }
}
.article-nav{
  display: flex;
  justify-content: flex-start;
}
.article-prev,
.article-next{
  padding: $space-xxs;
  background-color: #b6b6b6;
  & > a{
    color: #fff;
    text-decoration: none;
  }
}
.article-prev{
  margin-right: $space-xs;
}