// Breakpoints
$phone: 320px !default;
$smartphone: 480px !default;
$tablet: 768px !default;
$ipad: 1024px !default;
$laptop: 1140px !default;
$desktop: 1340px !default;

// spaces
$space-xxs: 0.4rem;
$space-xs: 0.8rem;
$space-s: 1.2rem;
$space-ms: 1.8rem;
$space-m: 2.4rem;
$space-ml: 3.2rem;
$space-l: 4rem;
$space-xl: 4.8rem;
$space-xxl: 5.6rem;

$spaces: (
  0: 0,
  6: #{$space-xxs},
  10: #{$space-xs},
  20: #{$space-s},
  30: #{$space-m},
  40: #{$space-l},
  50: #{$space-xl},
  60: #{$space-xxl},
  70: 70px,
  80: 80px,
  90: 90px,
  100: 100px,
);

// fonts
$font-xxs: 1rem;
$font-xs: 1.2rem;
$font-s: 1.4rem;
$font-ms: 1.8rem;
$font-m: 2.4rem;
$font-ml: 3rem;
$font-l: 3.6rem;
$font-xl: 4.8rem;
$font-xxl: 6.4rem;

//color
$white: #fff !default;
$black: #000 !default;
$gray: #f6f6f6 !default;
$lightgray: #fafafa !default;
$darkgray: #c0c0c0 !default;

$bg-primary: #e71a35 !default;
$bg-secondary: #d8d8d8 !default;
$bg-tertiary: #E5E8FA !default;
$bg-gray: #F9F9F9 !default;

$bg-colors: (
  primary: #{$bg-primary},
  secondary: #{$bg-secondary},
  tertiary: #{$bg-tertiary},
);

$color-primary: #273DB6 !default;
$color-secondary: #040303 !default;
$color-gray: #ABA9A9 !default;
$color-default: #000 !default;
$border-primary: #dddddd !default;
